import { Component } from "@angular/core";
import { TOKEN, CIM } from "src/environments/environment";
import UTILS from "src/app/shared/utils/utils";
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { Title } from "@angular/platform-browser";
import { filter, map } from "rxjs/operators";
import { SharedService } from "./shared/services/shared.service";
import { ScriptService } from "./shared/services/scripts.service";
// import { disableConsoleInProduction } from "./shared/services/utils.service";
import {
  MEMBER_POINT,
  USER_FULL_DETAILS,
} from "src/app/shared/constants/constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "ng-allianz";
  loading = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private api: SharedService,
    private scriptService: ScriptService
  ) {
    // disableConsoleInProduction();
    this.loadScript();
    console.log("Loading External Dynatrace Script Service");
    this.scriptService.load("DYNATRACE");
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          if (sessionStorage.getItem("INVALID_USER")) {
            this.loading = true;
          } else {
            this.loading = false;
          }
          this.getPathnameAndSet();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        var rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data) => {
          this.titleService.setTitle(
            `Allianz | Meine Vorteile - ${data.title}`
          );
          this.getUserDetailsResponse();
          // if (this.redirectBlockedUrl() && this.router.url !== "/blocked") {
          //   this.router.navigate(["/blocked"]);
          // } else {
          //   if (!this.notTicketshopTitle(data.title)) {
          //     this.expireReservationApi();
          //   }
          //   if (!/Gewinnspiel/.test(data.title)) {
          //     sessionStorage.removeItem(LOTTERY_USER_ANSWER);
          //   }
          // }

          if (!this.notTicketshopTitle(data.title)) {
            this.expireReservationApi();
          }
        });
      });
  }

  notTicketshopTitle(title) {
    if (
      /Ticketshop Warenkorb|Ticketshop Adressangaben|Ticketshop Zahlungsart|Ticketshop Bestätigung/.test(
        title
      )
    ) {
      return true;
    }
    return false;
  }

  expireReservationApi() {
    this.api.expireReservation().subscribe(
      (expire) => { },
      (e) => { }
    );
  }

  memberPointsResponse() {
    this.api.getPointScore().subscribe(
      (point) => {
        const { data, status } = point;
        if (status === "success") {
          let pointElem = document.querySelector("nx-link#remainingPoints > a");
          let __p = UTILS.roundOfPoints(Number(data.points));
          sessionStorage.setItem(MEMBER_POINT, String(__p));
          if (!!pointElem) pointElem.innerHTML = String(__p);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  getUserDetailsResponse() {
    this.api.getUserFullDetails().subscribe(
      (details) => {
        const { data, status } = details;
        if (status === "success") {
          sessionStorage.setItem(USER_FULL_DETAILS, JSON.stringify(data));
          let memberElement = document.querySelector(
            ".header-link--item.login #member-fullname div span"
          );
          if (memberElement) {
            let fullName = `${data.first_name} ${data.NobilityDescription !== null ? data.NobilityDescription : ""} ${data.last_name}`;
            memberElement.innerHTML = `${fullName.substr(0, 21)} ${fullName.length > 22 ? "..." : ""}`;
          }

          const isBlockedAndNonBlocked = this.redirectBlockedUrl(data);
          if (isBlockedAndNonBlocked) {
            this.router.navigate(["/blocked"]);
          } else if (this.router.url === "/blocked") {
            this.router.navigate(["/uebersicht"]);
          }
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  redirectBlockedUrl(userData) {
    if (
      userData &&
      "properties" in userData &&
      "LockingStatus" in userData.properties
    ) {
      const isLockingStatus = userData.properties.LockingStatus;
      if (isLockingStatus && isLockingStatus === "BLOCKED") {
        return true;
      }
    }
    return false;
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  getPathnameAndSet() {
    let pathLength = window.location.pathname.length;
    const arrayUrl = [
      `${CIM.redirect_uri}/coupon`,
      `${CIM.redirect_uri}/voucher`,
      `${CIM.redirect_uri}/ticketshop`,
    ];
    if (arrayUrl.indexOf(window.location.href) !== -1) {
      if (UTILS.isIEBrowser()) {
        this.router.navigate(["/internet-explorer"]);
        return;
      } else {
        this.router.navigate(["/404"]);
        return;
      }
    }
    if (pathLength < 3) {
      let token = sessionStorage.getItem(TOKEN.avp_token);
      let uri = `${window.location.origin}/uebersicht`;
      if (token) {
        window.location.href = uri;
      }
    }
  }

  ngAfterViewInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const jwtToken = sessionStorage.getItem(TOKEN.avp_token);
    if (jwtToken) {
      this.memberPointsResponse();
    }
    if (UTILS.isIEBrowser()) {
      this.router.navigate(["/internet-explorer"]);
      return;
    }
  }

  loadScript() {
    if (!!sessionStorage.getItem("token")) {
      this.scriptService.load("COOKIE");
      var idVar = setInterval(() => {
        let btnElement = document.querySelector(
          ".optanon-alert-box-button-container .optanon-alert-box-button-middle.accept-cookie-container"
        );
        let linkElement = document.querySelector(
          ".optanon-alert-box-button-container .optanon-alert-box-button.optanon-button-more button"
        );

        if (!!btnElement) {
          clearInterval(idVar);
          //@ts-ignore
          btnElement.setAttribute(
            "style",
            "background-color:#007ab3 !important; border-color: #007ab3 !important"
          );
          //@ts-ignore
          linkElement.setAttribute("style", "color: #007ab3 !important");
          console.log("screen.width", screen.width);
          if (
            UTILS.detectMobile() ||
            UTILS.detectTab() ||
            screen.width <= 1030
          ) {
            let mobileLayout = document.querySelector(
              ".optanon-alert-box-body"
            );
            let smallBody = document.querySelector(
              ".optanon-alert-box-button-container"
            );
            //@ts-ignore
            mobileLayout.setAttribute(
              "style",
              "margin-left: 30px !important; padding-top: 16px !important; max-width: 87%; margin-right: 300px !important"
            );

            smallBody.setAttribute(
              "style",
              "padding-right: 0; margin-top: auto !important;position: absolute !important; right: 0 !important;left: 8px !important; top: 66% !important;"
            );
          }

          if (!this.getCookieOverlay()) {
            let overlayElement = document.getElementById("optanon-popup-bg");
            if (!!overlayElement) {
              overlayElement.classList.add("overlay-popup-bg");
              btnElement.addEventListener("click", function () {
                overlayElement.classList.remove("overlay-popup-bg");
              });
              linkElement.addEventListener("click", function () {
                overlayElement.classList.remove("overlay-popup-bg");
              });
            }
          }
        }
      }, 400);
    }
  }

  getCookieOverlay() {
    var cookieArr = document.cookie.split(";");
    var isOverlay = false;
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if ("OptanonAlertBoxClosed" == cookiePair[0].trim()) {
        isOverlay = true;
      }
    }
    return isOverlay;
  }
}

(function () {
  document.body.addEventListener("mousedown", function () {
    document.body.classList.add("using-mouse");
  });

  document.body.addEventListener("keydown", function (event) {
    if (event.keyCode === 9) {
      document.body.classList.remove("using-mouse");
    }
  });
})();
